
  import Vue from "vue";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-large-avatars-list",
    props: {
      usersList: {
        type: Array,
        default: [] as any[],
        required: false,
      },
      numAvatarsRow: {
        type: Number,
        default: 5,
        required: false,
      },
    },
    data() {
      return {
        routes: ROUTES,
      }
    },
  });
