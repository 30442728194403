
  import Vue, {PropType} from "vue";
  import PwaMultiplayerHeader from "@/components/competitions/PwaMultiplayerHeader.vue";
  import PwaLargeAvatarsList from "@/components/competitions/PwaLargeAvatarsList.vue";
  import {COMPETITION_STATUS_ID, RegisterCompetitionErrorKey} from "@/utils/competition";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import PwaPlayGame from "@/components/competitions/PwaPlayGame.vue";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";
  import PwaInscriptionNoMoney from "@/components/modals/competitions/PwaInscriptionNoMoney.vue";
  import PwaCompetitionRules from "@/components/competitions/PwaCompetitionRules.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaMultiplayerClassification from "@/components/competitions/PwaMultiplayerClassification.vue";
  import PwaCountRoller from "@/components/common/PwaCountRoller.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaCurrency from "@/components/common/PwaCurrency.vue";
  import {EVENTS} from "@/utils/events";
  import { getLastTimeMultiplayer } from '@/utils/common';
  import { showInterstitial } from '@/services/ads';

  export default Vue.extend({
    name: "pwa-multiplayer",
    components: {
      PwaMultiplayerHeader,
      PwaLargeAvatarsList,
      PwaCompetitionRules,
      PwaIcon,
      PwaMultiplayerClassification,
      PwaCountRoller,
      PwaPrice,
      PwaCurrency,
      PwaCountdownMessage: () => import("@/components/common/PwaCountdownMessage.vue"),
      PwaWebAdBillboardBanner: () => import('@/components/ads/PwaWebAdBillboardBanner.vue'),
    },
    props: {
      competition: {
        type: Object,
        required: true
      },
      gfyScore: {
        type: [Object, Array],
        required: true
      },
      onReloadCompetitionData: {
        type: Function as PropType<() => void>,
        required: true,
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: null,
      },
      classification: {
        type: Array,
        required: true,
      },
      userClassificationPos: {
        type: Number,
        required: false,
        default: null,
      },
      players: {
        type: Array,
        required: true,
      },
      hasTimeLeft: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        termsAndConditionsAccepted: false,
        forceDisabledButton: false,
        useCompetitionFreeInscription: 0,
      }
    },
    mounted(): void {
      this.$root.$on(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, () => {
        this.termsAndConditionsAccepted = false;
      });
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.ON_PLAY_GAME_MODAL_CLOSE);
    },
    computed: {
      competitionStatusId(): number {
        return this.competition.status_id ? this.competition.status_id : 0;
      },
      isCompetitionPending(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.PENDING;
      },
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
      isCompetitionFinished(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED;
      },
      isCompetitionCancelled(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CANCELLED;
      },
      canUserPlay(): boolean {
        let canUserPlay = false;
        if (this.isCompetitionPending || this.isCompetitionStarted) {
          canUserPlay = this.arePlacesAvailable || this.isUserInCompetition;
        }

        return canUserPlay;
      },
      arePlacesAvailable(): boolean {
        return this.isCompetitionPending && this.competition.occupation < this.competition.max_players;
      },
      isUserInCompetition(): boolean {
        return this.userClassificationPos !== null || Boolean(this.players.find((player: any) => player.user_id === this.userId));
      },
      competitionLevel() {
        const minLevel = this.competition && this.competition.min_user_level > 0 ? (this.competition.min_user_level + 1) : 0;
        const maxLevel = this.competition ? this.competition.max_user_level + 1 : 0;

        return `${minLevel} - ${maxLevel}`;
      },
      availablePlaces(): number {
        return this.competition.max_players - this.competition.occupation;
      },
    },
    methods: {
      getLastTimeMultiplayer,
      async playUserMultiplayer() {
        this.forceDisabledButton = true;
        this.showLoader(true);
        try {
          const {data} = await this.$http.user.postUserMultiplayer(this.userId, this.competition.id, {use_competition_free_inscription: this.useCompetitionFreeInscription});
          const response = data.data;
          let resultId = response;
          let numAttempt = 1;
          if (typeof response === 'object') {
            resultId = response.result_id;
            numAttempt = response.num_attempt;
          }

          this.onRegisterUserMultiplayerSuccess(resultId, numAttempt);
        } catch ({response}) {
          const errorData = response?.data || {};
          const key = errorData?.key;
          const {NO_MONEY} = RegisterCompetitionErrorKey;
          if (key === NO_MONEY) {
            this.showPopupNoMoney(this.competition.cost_inscription);
          } else {
            const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
            this.showToastError(this.$t('139'), errorMessage);
          }
          this.showLoader(false);
        } finally {
          this.forceDisabledButton = false;
        }
      },
      async onRegisterUserMultiplayerSuccess(resultId: number, numAttempt: number) {
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        await showInterstitial();
        this.showLoader(false);
        const gameAlias = this.competition.game_alias;
        if (this.isDesktop) {
          const message = this.$createElement(PwaPlayGame, {
            props: {
              isModal: true,
              gameAlias: gameAlias,
              competitionId: this.competition.id,
              resultId,
              numAttempt,
            },
          });
          const title = this.$t('549');
          this.showModal({
            id: MODAL_IDS.MODAL_PLAY_GAME,
            title,
            message,
            hideCancel: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            dialogClass: 'game-container',
            headerClass: 'title-centered',
            contentClass: 'create-match-background',
            footerClass: 'buttons-hidden',
          });
        } else {
          const params: Record<string, string|number> = {
            gameAlias: gameAlias,
            competitionId: this.competition.id,
            resultId: resultId,
            numAttempt,
          };
          this.routerPush(ROUTES.playGame.name, params);
        }
      },
      onRegisterPlayClick(useCompetitionFreeInscription = 0) {
        if (this.termsAndConditionsAccepted) {
          if (!this.isLoggedIn) {
            this.goToSignup();
          } else {
            // Comprobamos si tiene saldo
            if (!useCompetitionFreeInscription && this.walletQuantity < this.competition.cost_inscription) {
              this.showPopupNoMoney(this.competition.cost_inscription);
            } else {
              this.useCompetitionFreeInscription = useCompetitionFreeInscription;
              this.playUserMultiplayer();
            }
          }
        } else {
          this.showToastError(this.$t('139'), this.$t('151'));
        }
      },
      showPopupNoMoney(costInscription: number) {
        const message = this.$createElement(PwaInscriptionNoMoney, {
          props: {
            create: false,
            costInscription,
          },
        });
        this.showModal({
          id: 'modal-no-money',
          message,
          showOnRoot: true,
          contentClass: {'no-money': true, 'is-desktop': this.isDesktop, 'hide-footer': true},
        });
      },
    },
  });
