
  import Vue from "vue";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import {ROUTES} from "@/router/routes";
  import PwaMultiplayer from "@/components/competitions/PwaMultiplayer.vue";
  import {COMPETITION_STATUS_ID} from "@/utils/competition";
  import {EVENTS} from "@/utils/events";
  import PwaDifferentLevelModal from "@/components/modals/competitions/PwaDifferentLevelModal.vue";
  import {getDate} from "@/utils/date";
  import moment from 'moment-timezone';

  export default Vue.extend({
    name: "Multiplayer",
    mixins: [pageMixin],
    components: {
      PwaMultiplayer,
      PwaDifferentLevelModal,
    },
    props: {
      competitionId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        competition: {} as any,
        gfyScore: {},
        competitionsFreeInscriptionsId: null,
        classification: [],
        userClassificationPos: null,
        players: [],
        autoRefreshMultiplayerPageTimeout: null,
        userLevel: 0,
        competitionLevel: 0,
        userHasLowerLevel: false,
        showDifferentLevelMessageSeen: false,
        hasTimeLeft: true,
        hasTimeLeftTimeout: null,
      }
    },
    mounted(): void {
      this.getMultiplayerPage(true);
      this.$root.$on(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, () => this.getMultiplayerPage(true));
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.ON_PLAY_GAME_MODAL_CLOSE);
      this.clearAutoRefreshMultiplayerPageTimeout();
      this.clearHasTimeLeftTimeout();
    },
    computed: {
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {
            text: this.competition.game_name || '-',
            to: this.competition.game_alias ? {name: ROUTES.minigame.name, params: {lang: this.routeLang, gameAlias: this.competition.game_alias}} : null,
          },
          {text: this.competition.region || '-'},
        ];
      },
      competitionStatusId(): number {
        return this.competition.status_id ? this.competition.status_id : 0;
      },
      isCompetitionPending(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.PENDING;
      },
      isCompetitionCancelled(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CANCELLED;
      },
      isCompetitionFinished(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED;
      },
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
    },
    methods: {
      async getMultiplayerPage(showLoader = false) {
        this.clearAutoRefreshMultiplayerPageTimeout();
        if(showLoader) {
          this.showLoader(true);
        }
        try {
          const {data} = await this.$http.page.getMultiplayerPage(this.competitionId, {});
          this.competition = data.data.competition || {};
          this.gfyScore = data.data.gfy_score || {};
          this.players = data.data.players || [];
          this.competitionsFreeInscriptionsId = data.data.competitions_free_inscriptions_id || null;
          this.classification = data.data.classification || [];
          this.userClassificationPos = data.data.user_classification_pos || data.data.user_classification_pos === 0
            ? data.data.user_classification_pos
            : null;
          this.userLevel = data.data.user_level || data.data.user_level === 0
            ? data.data.user_level
            : null;
          if (!this.isCompetitionFinished && !this.isCompetitionCancelled) {
            this.autoRefreshMultiplayerPageTimeout = setTimeout(() => {
              this.getMultiplayerPage();
            }, 60000);
          }

          if (!this.showDifferentLevelMessageSeen && this.isCompetitionPending && this.userClassificationPos === null && this.userLevel !== null) {
            let showModal = false;
            if(this.userLevel > this.competition.max_user_level) {
              this.userHasLowerLevel = false;
              this.competitionLevel = this.competition.max_user_level;
              showModal = true;
            } else if (this.userLevel < this.competition.min_user_level) {
              this.userHasLowerLevel = true;
              this.competitionLevel = this.competition.min_user_level;
              showModal = true;
            }

            if (showModal) {
              const differentLevelModal: any = this.$refs.differentLevelModal;
              differentLevelModal.$refs.modal.show();
              this.showDifferentLevelMessageSeen = true;
            }
          }

          this.clearHasTimeLeftTimeout();
          if (this.competition.end_date && this.isCompetitionStarted) {
            const endDate = getDate(this.competition.end_date).utc();
            const diff = moment.duration(endDate.diff(moment.utc(), 'millisecond', true));
            const seconds = diff.asSeconds();
            if (seconds > 0) {
              this.hasTimeLeftTimeout = setTimeout(() => {
                this.hasTimeLeft = false;
              }, seconds * 1000);
            } else {
              this.hasTimeLeft = false;
            }
          } else if (this.isCompetitionPending) {
            this.hasTimeLeft = true;
          } else {
            this.hasTimeLeft = false;
          }

        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      clearAutoRefreshMultiplayerPageTimeout(): void {
        if (this.autoRefreshMultiplayerPageTimeout) {
          clearTimeout(this.autoRefreshMultiplayerPageTimeout);
          this.autoRefreshMultiplayerPageTimeout = null;
        }
      },
      clearHasTimeLeftTimeout(): void {
        if (this.hasTimeLeftTimeout) {
          clearTimeout(this.hasTimeLeftTimeout);
          this.hasTimeLeftTimeout = null;
        }
      },
    },
    watch: {
      competitionId() {
        this.getMultiplayerPage(true);
      },
    },
  });
