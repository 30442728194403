
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import {ordinal} from "@/utils/common";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-multiplayer-classification",
    components: {
      PwaIcon,
      PwaPrice,
    },
    props: {
      classification: {
        type: Array,
        required: true,
      },
      userClassificationPos: {
        type: Number,
        required: false,
        default: null,
      },
      awards: {
        type: Array,
        required: true,
      },
    },
    computed: {
      userClassification(): any {
        return this.userClassificationPos !== null && this.classification[this.userClassificationPos]
          ? this.classification[this.userClassificationPos]
          : null;
      },
    },
    methods: {
      goToUserProfile(username: string) {
        if (username) {
          this.$router.push({name: ROUTES.profile.name, params: {lang: this.routeLang, username}});
        }
      },
      ordinal(num: number) {
        return ordinal(num);
      },
    },
  });
